import React from 'react';
import './Gallery.css';
import image from './image.png';

function Gallery() {
  const nftData = [
    { id: 1, title: 'NFT #1', description: 'Brief description of the NFT', link: 'https://opensea.io/assets/1' },
    { id: 2, title: 'NFT #2', description: 'Brief description of the NFT', link: 'https://opensea.io/assets/2' },
    { id: 3, title: 'NFT #3', description: 'Brief description of the NFT', link: 'https://opensea.io/assets/3' },
    { id: 4, title: 'NFT #4', description: 'Brief description of the NFT', link: 'https://opensea.io/assets/4' },
    // Add more NFT objects as needed
  ];

  return (
    <section id="gallery" className="gallery">
      <h2>Explore the Collection</h2>
      <div className="gallery-grid">
        {nftData.map(nft => (
          <div key={nft.id} className="nft-card">
            <img src={image} alt={nft.title} />
            <div className="nft-info">
              <h3>{nft.title}</h3>
              <p>{nft.description}</p>
              <a 
                href={nft.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="buy-button"
              >
                Buy on OpenSea
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Gallery;
