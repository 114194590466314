import React from 'react';
import './DiscordSection.css';

function DiscordSection() {
  return (
    <section id="discord" className="discord-section">
      <div className="content">
        <h2>Be Part of the Journey: The Legacy Begins Now</h2>
        <p>
          This isn’t just about owning art; it’s about being part of a living legacy. Join our community to connect with like-minded collectors who believe in second chances, dramatic transformations, and the power of reinvention. By purchasing an NFT from this collection, you’re not only investing in the digital space but also in a story that defies the odds. Stay tuned on Discord for exclusive behind-the-scenes content as (to be announced) prepares for his Marvel debut, and be part of a movement that brings the world’s first real-life villain to the silver screen. Don’t just watch history unfold — be a part of it.
        </p>
        <a 
          href="https://discord.gg/xgeNKYhk" 
          target="_blank" 
          rel="noopener noreferrer"
          className="discord-button"
        >
          Join Our Discord
        </a>
      </div>
    </section>
  );
}

export default DiscordSection;
