// src/components/OwnershipTransfer.js
import React from 'react';
import './OwnershipTransfer.css';

function OwnershipTransfer() {
  return (
    <section id="ownership-transfer" className="ownership-transfer">
      <div className="content">
        <h2>Your NFT, Your Asset: A Digital Piece of Cultural Evolution</h2>
        <p>
          Owning one of these NFTs means much more than holding a digital collectible. It’s about owning a piece of cultural evolution — a story where reality meets fiction. As (to be announced) transforms from one of the world’s most notorious inmates into a Marvel villain, your NFT represents a tangible link to that groundbreaking moment. Learn how to protect, manage, and even trade your asset as the story continues to unfold.
        </p>
      </div>
    </section>
  );
}

export default OwnershipTransfer;
